// eslint-disable-next-line import/no-anonymous-default-export
export default function (itemID) {
  let userFound: any = false;

  var query = `query {  boards(ids:[2316385607, 2316395315, 2316399067]) {id items (ids:${itemID}) { name column_values{ title value } } } }`;
  return fetch("https://api.monday.com/v2", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
    },
    body: JSON.stringify({
      query: query,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      res.data.boards.forEach((board) => {
        board.items.forEach((item) => {
          userFound = {
            name: item.name,
            id: JSON.parse(
              item.column_values.find((c) => c.title === "User ID").value
            ),
          };
        });
      });

      if (!userFound) {
        return null;
      }
      var query = `
      query {
        boards (ids: 2225844788) {
          groups(ids:[ "duplicate_of_intake_calls_sche",
              "topics",
              "duplicate_of_intake_calls",
              "new_group59816",
              "new_group15044"]){
            items{
               id
                  name
                  group {
                    title
                  }
                  column_values{
                    id
                    value
                    title
                  }
            }
          }
        }
      }
      `


      return fetch("https://api.monday.com/v2", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
        },
        body: JSON.stringify({
          query: query,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          var listOfDeals = [];
          for (const item of res.data.boards[0].groups.map(g => g.items).flat()) {
            const userId = JSON.parse(
              item.column_values.find((c) => c.id === "people").value
            )?.personsAndTeams?.[0]?.id?.toString()

            if (userId !== userFound.id) {
              continue
            }
            listOfDeals.push(item);
          }
          return { dealOptions: listOfDeals, repName: userFound.name };
        });
    });
}
