import React from "react";
import "./first-form.styles.scss";
import Logo from "../../assets/logo.webp";
import FirstFormComponent from "./FirstFormComponent.tsx";
import { Paper } from "@mui/material";

const FirstForm = () => {
  return (
    <div className="first-form">
      <Paper className="form-background" elevation={9}>
        <div className="logo-container">
          <img className="logo" src={Logo} />
        </div>

        <FirstFormComponent />
      </Paper>
    </div>
  );
};
export default FirstForm;
