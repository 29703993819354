import TextField from "../../../components/TextField.tsx";
import getDealOptions from "../../../utils/getDealOptions.ts";

export default ({
  salesRepId,
  setSalesRepId,
  setDealOptions,
  setRepName,
  loading,
  setLoading,
}) => {
  return (
    <>
      <TextField
        label="Sales Rep ID"
        required
        type="number"
        value={salesRepId}
        onChange={async (e) => {
          setSalesRepId(e.target.value);
          if (e.target.value.length === 10) {
            setLoading(true);
            const result = await getDealOptions(e.target.value);
            setRepName(result?.repName);
            setDealOptions(result?.dealOptions);
            setLoading(false);
          }
        }}
        disabled={loading}
      />
    </>
  );
};
