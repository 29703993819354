import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import transformDeal from "../../utils/transformDeal.ts";
import DealSelect from "./components/DealSelect.tsx";
import Form from "./components/Form.tsx";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [salesRepId, setSalesRepId] = useState("");
  const [dealOptions, setDealOptions] = useState([]);

  const [selectedDeal, setSelectedDeal] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [repName, setRepName] = useState(null);

  const submitForm = () => {
    setLoading(true);
    fetch("https://hook.integromat.com/rtxq4xif3s2hbfm2rsgtnjbg5d0ujs4b", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(selectedDeal),
    })
      .then((res) => {
        if (res.ok) {
          navigate("/submitSuccess");
        } else {
          setErrorMessage("Error while submitting form");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "grid",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {repName && <b>Welcome {repName}!</b>}
      <DealSelect
        setSalesRepId={setSalesRepId}
        setRepName={setRepName}
        setDealOptions={setDealOptions}
        loading={loading}
        setLoading={setLoading}
        salesRepId={salesRepId}
      />
      {salesRepId.length === 10 && (
        <>
          {dealOptions === null ? (
            <>No deals found</>
          ) : (
            <FormControl fullWidth>
              <InputLabel>Deals</InputLabel>
              <Select
                value={selectedDeal?.id}
                label="Deals"
                onChange={(e) => {
                  setSelectedDeal(
                    transformDeal(
                      dealOptions.find((deal) => deal.id === e.target.value)
                    )
                  );
                }}
              >
                {dealOptions?.map((deal) => (
                  <MenuItem key={deal.id} value={deal.id}>
                    {deal.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
      {selectedDeal && (
        <>
          <Form deal={selectedDeal} setDeal={setSelectedDeal} />
          <Button variant="contained" disabled={loading} onClick={submitForm}>
            Submit Deal
          </Button>
        </>
      )}
      {errorMessage && (
        <p style={{ color: "red" }}>
          <b>Error while submitting form</b>
        </p>
      )}
    </>
  );
};
