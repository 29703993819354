function getParsedValue(deal, column_id, key?) {
  const parsedData = JSON.parse(
    deal.column_values.find((columnValue) => columnValue.id === column_id).value
  );
  return (key ? parsedData?.[key] : parsedData) || "";
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (deal) {
  const phoneNumber = getParsedValue(deal, "phone", "phone");
  const email = getParsedValue(deal, "email", "email");
  const appointmentDate = getParsedValue(deal, "date2", "date");
  const appointmentTime = getParsedValue(deal, "date2", "time");
  const address = `${getParsedValue(
    deal,
    "long_text",
    "text"
  )} ${getParsedValue(deal, "text08")} ${getParsedValue(
    deal,
    "text4"
  )} ${getParsedValue(deal, "text5")}`;
  const state = getParsedValue(deal, "text4");

  return {
    id: deal.id,
    phoneNumber,
    name: deal.name,
    email,
    appointmentDate,
    appointmentTime,
    address,
    state,
    preferredLanguage: "English",
    coordinates: "35.0873, -106.65",
    notes: "",
    utility: "",
    calendarReminder: false,
    designPreference: "",
    jobSource: "Online",
    isNewRoofRequired: getParsedValue(deal, "is_new_roof_required_", "checked"),
    roofType: "",
  };
}
