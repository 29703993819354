import TextField from "../../../components/TextField.tsx";
import LanguageList from "language-list";
import {
  Autocomplete as MuiAutoComplete,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
} from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import Marker from "google-map-react";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { MobileDatePicker, TimePicker } from "@mui/lab";
import moment from "moment";
import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

const LocationPin = () => (
  <div className="pin">
    <Icon icon="oi:map-marker" className="pin-icon" />
  </div>
);

let interval;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ deal, setDeal }) => {
  const [loading, setLoading] = useState(false);
  const [utilityBillUploaded, setUtilityBillUploaded] = useState(false);
  const [utilityList, setUtilityList] = useState([]);

  const checkUtilityBillUploaded = () => {
    return fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
      },
      body: JSON.stringify({
        query: `query {
          items (ids: [${deal.id}]) {
              name
              column_values(ids:["dup__of_request_utility_bill"]){
                title
                id
                value
              }
          }
      }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          JSON.parse(res?.data?.items?.[0]?.column_values?.[0]?.value || "{}")
            ?.files?.length > 0
        ) {
          setUtilityBillUploaded(true);
          clearInterval(interval);
        }
      });
  };

  useEffect(() => {
    checkUtilityBillUploaded();
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(checkUtilityBillUploaded, 5000);
  }, []);

  useEffect(() => {
    const query =
      "query { boards (ids:2139177078) { name items { group { id title } name } } }";
    fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyODIyMzY0NCwidWlkIjoyNTA1NjIwOSwiaWFkIjoiMjAyMS0xMC0xMVQxODo0Nzo1Ni4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTk2MTc1OCwicmduIjoidXNlMSJ9.Lo3aJk-3s_XAy-u1vQWY77OvZPtR9BlEFMNefXAh7tI",
      },
      body: JSON.stringify({
        query: query,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setUtilityList(
          res.data.boards[0].items
            .filter((item) => item.group.title === deal.state)
            .map((item) => item.name)
        );
      });
  }, [deal.state]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <TextField
        label="Name"
        value={deal.name}
        onChange={(e) => setDeal({ ...deal, name: e.target.value })}
      />
      <TextField
        label="Email"
        value={deal.email}
        onChange={(e) => setDeal({ ...deal, email: e.target.value })}
      />
      <TextField
        label="Phone Number"
        value={deal.phoneNumber}
        onChange={(e) => setDeal({ ...deal, phoneNumber: e.target.value })}
      />
      <MuiAutoComplete
        value={deal.preferredLanguage}
        options={LanguageList()
          .getData()
          .map((l) => l.language)}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Preferred Language*"
            variant="outlined"
          />
        )}
        onChange={(_, data) => setDeal({ ...deal, preferredLanguage: data })}
      />
      <Autocomplete
        className="google-autocomplete"
        options={{
          componentRestrictions: { country: "us" },
          types: ["address"],
        }}
        onPlaceSelected={(place) => {
          if (!place || !place.formatted_address) {
            return;
          }
          setDeal({
            ...deal,
            address: place.formatted_address,
            state: place.address_components.find((a) =>
              a.types.includes("administrative_area_level_1")
            ).short_name,
            coordinates:
              place.geometry.location.lat() +
              ", " +
              place.geometry.location.lng(),
          });
        }}
      />
      <div className="google-map-container">
        <GoogleMapReact
          defaultZoom={13}
          defaultCenter={{ lat: 35.0873, lng: -106.65 }}
          center={{
            lat: Number(deal.coordinates.split(", ")[0]),
            lng: Number(deal.coordinates.split(", ")[1]),
          }}
          options={{ mapTypeControl: true }}
        >
          <LocationPin />
          <Marker lat={35.0873} lng={-106.65} text="my marker" />
        </GoogleMapReact>
      </div>
      <>Selected Address: {deal.address}</>
      <br />
      <br />
      <Button
        variant="contained"
        onClick={() => {
          setLoading(true);
          fetch(
            "https://hook.integromat.com/j3ocaiyrwsl1f6rrpns5e3jp8icahnw1",
            {
              method: "POST",
              body: JSON.stringify({
                deal: deal.id,
                email: deal.email,
                phoneNumber: deal.phoneNumber,
              }),
            }
          )
            .then(() => {
              alert("Requested utility bill");
            })
            .finally(() => setLoading(false));
        }}
      >
        {loading ? (
          <CircularProgress style={{ color: "white" }} size={24} />
        ) : (
          "Request Utility Bill"
        )}
      </Button>
      <br />
      {utilityBillUploaded ? (
        <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
          <CheckIcon htmlColor="green" /> <span>Utility bill uploaded</span>
        </Stack>
      ) : (
        <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
          <CircularProgress size={24} />{" "}
          <span>Waiting for utility bill to be uploaded</span>
        </Stack>
      )}
      <br />

      <MobileDatePicker
        label="Date *"
        value={moment(deal.appointmentDate, "YYYY-MM-DD")}
        inputFormat="YYYY-MM-DD"
        onChange={(value) => {
          setDeal({ ...deal, appointmentDate: value.format("YYYY-MM-DD") });
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      <TimePicker
        renderInput={(params) => <TextField {...params} />}
        label="Time *"
        inputFormat="hh:mm:ss"
        value={moment(deal.appointmentTime, "HH:mm:ss")}
        onChange={(value) => {
          setDeal({ ...deal, appointmentTime: value.format("HH:mm:ss") });
        }}
        shouldDisableTime={(timeValue, clockType) => {
          if (clockType === "minutes" && timeValue % 15) {
            return true;
          }
          return false;
        }}
      />
      <TextField
        label="Appointment Notes"
        value={deal.notes}
        onChange={(e) => setDeal({ ...deal, notes: e.target.value })}
        multiline
        rows={3}
      />
      <FormControl fullWidth>
        <InputLabel>Utility</InputLabel>
        <Select
          value={deal.utility}
          label="Utility"
          onChange={(e) => {
            setDeal({ ...deal, utility: e.target.value });
          }}
        >
          {utilityList.map((utility) => (
            <MenuItem key={utility} value={utility}>
              {utility}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Switch
            checked={deal.calendarReminder}
            onChange={(e) =>
              setDeal({ ...deal, calendarReminder: e.target.checked })
            }
          />
        }
        label="Send calendar reminder"
      />

      <TextField
        label="Design Preference"
        value={deal.designPreference}
        onChange={(e) => setDeal({ ...deal, designPreference: e.target.value })}
        multiline
        rows={3}
      />
      <FormControlLabel
        control={
          <Switch
            checked={deal.isNewRoofRequired}
            onChange={(e) => {
              setDeal({
                ...deal,
                isNewRoofRequired: e.target.checked,
                roofType: e.target.checked ? deal.roofType : "",
              });
            }}
          />
        }
        label="Is a new roof required?"
      />
      {deal.isNewRoofRequired && (
        <FormControl fullWidth>
          <InputLabel>Roof type</InputLabel>
          <Select
            value={deal.roofType}
            label="Roof type"
            onChange={(e) => {
              setDeal({ ...deal, roofType: e.target.value });
            }}
          >
            <MenuItem value={"Shingle"}>Shingle</MenuItem>
            <MenuItem value={"Tiled (12 Cent Adder)"}>
              Tiled (12 Cent Adder)
            </MenuItem>
            <MenuItem value={"Metal (12 Cent Adder)"}>
              Metal (12 Cent Adder)
            </MenuItem>
            <MenuItem value={"Flat (12 Cent Adder)"}>
              Flat (12 Cent Adder)
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </LocalizationProvider>
  );
};
